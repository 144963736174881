import { lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"
import viewStatsRoute from "@/pages/Games/CPI/Statistics/route"
import cpiFormRoute from "@/pages/Games/CPI/Form/route"
import i18next from "i18next"
import ProtectedRoute from "@/components/ProtectedRoute"
import { hasModuleRoutePermission } from "@/util/permissions"

const CpiList = lazy(() => import("@/pages/Games/CPI/List/CpiList"))

const route: RouteObject = {
  path: "/games/cpi-test",
  element: (
    <ProtectedRoute hasPermission={hasModuleRoutePermission("cpi")}>
      <Outlet />
    </ProtectedRoute>
  ),
  handle: {
    pageTitle: () => i18next.t("docTitle", { ns: "cpiTest" }),
    title: () => i18next.t("pageTitle", { ns: "cpiTest" }),
    crumb: () => i18next.t("pageTitle", { ns: "cpiTest" }),
  },
  children: [
    { index: true, element: <CpiList /> },
    viewStatsRoute,
    ...cpiFormRoute,
  ],
}
export default route
