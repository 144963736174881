export const USER_TYPE = {
  internal: {
    admin: "internal_admin",
    restrictedUser: "internal_restricted_user",
  },
  external: {
    admin: "external_admin",
    restrictedUser: "external_restricted_user",
  },
} as const

export const ADMIN_TYPES: string[] = [
  USER_TYPE.internal.admin,
  USER_TYPE.external.admin,
]

export const PERMISSION_MODULES = {
  organisation: "organisation",
  knowledgeBase: "kwalee_academy",
  discussionForum: "discussion_forum",
  games: "my_games",
  cpi: "cpi",
  fileManagement: "file_management",
  creativeManagement: "creative_management",
} as const
