export const sanitiseHtml = (html: string) => html.replace(/(<([^>]+)>)/gi, "")

export const extractWordsAround = (
  text: string,
  searchTerm: string,
  numWords: number,
) => {
  const index = text.indexOf(searchTerm)

  if (index === -1) {
    return text
  }

  const startIndex = Math.max(0, index - numWords)
  const endIndex = Math.min(
    text.length - 1,
    index + searchTerm.length - 1 + numWords,
  )

  const result = text.slice(startIndex, endIndex + 1)

  return result
}

export const getArticleTags = (tags: string): string[] => {
  const doesTagsHaveCurlyBraces =
    tags.charAt(0) === "{" && tags.charAt(tags.length - 1) === "}"

  const trimmedTags = doesTagsHaveCurlyBraces
    ? tags.slice(1, tags.length - 1)
    : tags

  if (!trimmedTags) return []

  return trimmedTags.split(",")
}

export const prependURLProtocol = (url: string): string => {
  if (!/^https?:\/\//i.test(url)) {
    url = "https://" + url
  }

  return url
}

export const getFileNameWithoutExtension = (fileNameWithExtension: string) =>
  fileNameWithExtension.slice(0, fileNameWithExtension.lastIndexOf("."))

export const splitString = (str: string, splitOn: string) => {
  return str
    .split(splitOn)
    .map((item) => item.trim())
    .filter((item) => item.length > 0)
}
