import { Theme } from "@mui/material"

const Select: NonNullable<Theme["components"]>["MuiSelect"] = {
  defaultProps: {
    inputProps: {
      disableUnderline: true,
    },
  },
  styleOverrides: {
    icon: ({ ownerState }) =>
      ownerState.readOnly
        ? {
            visibility: "hidden",
          }
        : undefined,
    root: ({ ownerState, theme }) => ({
      textTransform: "capitalize",
      ...(ownerState.variant === "outlined" && {
        "&.MuiInputBase-root.Mui-error .MuiSelect-outlined": {
          borderColor: "#F64B3C",
        },
        "& .MuiSelect-outlined": {
          borderRadius: 4,
          fontSize: 14,
          border: `1px solid`,
          borderColor: theme.palette.tertiary.light,
          background: "transparent",
          padding: "6px",
          [theme.breakpoints.only("xs")]: { fontSize: 12 },
        },
        "fieldset.MuiOutlinedInput-notchedOutline": {
          borderColor: "none !important",
        },
      }),

      ...(ownerState.variant === "outlined" &&
        ownerState.readOnly && {
          "&.Mui-readOnly.Mui-focused fieldset": {
            border: `1px solid`,
            borderColor: theme.palette.tertiary.light,
          },

          ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.Mui-readOnly.MuiInputBase-readOnly":
            {
              padding: theme.spacing(0, 0.75),
              cursor: "default",
            },
        }),

      ...(ownerState.variant === "outlined" &&
        ownerState.size === "medium" && {
          "&  .MuiInputBase-input": {
            padding: theme.spacing(2, 1),
          },
        }),

      ...(ownerState.variant === "standard" && {
        "& .MuiSelect-standard": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#313131" : "#FDFDFD",
          color: `${theme.palette.getContrastText(
            theme.palette.mode === "dark" ? "#313131" : "#FDFDFD",
          )}`,
          borderRadius: 8,
          padding: theme.spacing(1, 1.5),
          fontSize: 14,
          position: "relative",
          boxShadow: "none",
          ":focus": {
            backgroundColor:
              theme.palette.mode === "dark" ? "#313131" : "#FDFDFD",
            borderRadius: 8,
          },
        },
      }),
      ...(ownerState.variant === "filled" && {
        backgroundColor: theme.palette.mode === "dark" ? "#313131" : "#F5F5F7",
        borderRadius: 8,
        border: `1px solid`,
        borderColor: theme.palette.tertiary.light,

        "&.MuiInputBase-root.Mui-error": { borderColor: "#F64B3C" },
        "& .MuiSelect-filled": {
          ":focus": {
            background: theme.palette.mode === "dark" ? "#19191A" : "#FFF",
          },
        },
        svg: {
          color: theme.palette.mode === "dark" ? "#A0A0A0" : "#1E1E1E",
        },
        "& .MuiInputBase-input": {
          background: theme.palette.mode === "dark" ? "#19191A" : "#FFF",
          width: "100%",
          borderRadius: 4,
          outline: 0,
          padding: 4,
          border: `1px solid transparent`,
          margin: "6px 6px 6px 20px",
          fontSize: 13,
          ":focus": {
            border: `1px solid`,
            borderColor: theme.palette.tertiary.light,
          },
        },
      }),
    }),
  },
}

export default Select
