import { LoaderFunctionArgs, RouteObject } from "react-router-dom"
import { store } from "@/app/store"
import { GetGameTitle } from "@/features/game/Game"
import { lazy } from "react"
import i18next from "i18next"
import ProtectedRoute from "@/components/ProtectedRoute"
import { hasModuleRoutePermission } from "@/util/permissions"

const FormCPI = lazy(() => import("@/pages/Games/CPI/Form/CpiForm"))

const route: RouteObject[] = [
  {
    path: "/games/cpi-test/new",
    handle: {
      pageTitle: () => i18next.t("docTitle", { ns: "cpiTest" }),
      title: () => i18next.t("label.newTest", { ns: "cpiTest" }),
      crumb: () => i18next.t("label.newTest", { ns: "cpiTest" }),
    },
    element: (
      <ProtectedRoute hasPermission={hasModuleRoutePermission("cpi", true)}>
        <FormCPI />
      </ProtectedRoute>
    ),
  },
  {
    path: "/games/cpi-test/:gameId/new",
    handle: {
      pageTitle: () => i18next.t("docTitle", { ns: "cpiTest" }),
      title: () => i18next.t("label.newTest", { ns: "cpiTest" }),
      crumb: () => i18next.t("label.newTest", { ns: "cpiTest" }),
    },
    element: (
      <ProtectedRoute hasPermission={hasModuleRoutePermission("cpi", true)}>
        <FormCPI />
      </ProtectedRoute>
    ),
  },
  {
    path: "/games/cpi-test/:gameId/:cpiTestId",
    handle: {
      crumb: (data: string) =>
        data ?? i18next.t("label.editCpi", { ns: "cpiTest" }),
    },
    loader: async ({ params }: LoaderFunctionArgs) => {
      let title = null
      if (!isNaN(params.gameId)) {
        const parsedParams = { id: parseInt(params.gameId) }
        const getter = store.dispatch(GetGameTitle.initiate(parsedParams))
        await getter
        const response = GetGameTitle.select(parsedParams)(store.getState())

        if (
          (response.data?.game?.length ?? 0) > 0 &&
          response.data!.game![0].name
        ) {
          title = response.data!.game![0].name

          getter.unsubscribe()
        }
        return title
      }
    },
    element: <FormCPI />,
  },
]
export default route
