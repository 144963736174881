import {
  BaseQueryArg,
  BaseQueryExtraOptions,
} from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import { BaseQueryFn, FetchArgs } from "@reduxjs/toolkit/dist/query/react"
import {
  BaseQueryApi,
  BaseQueryError,
  BaseQueryMeta,
  BaseQueryResult,
  QueryReturnValue,
} from "@reduxjs/toolkit/src/query/baseQueryTypes"
import merge from "lodash.merge"
import cloneDeep from "lodash.clonedeep"
import { RootState } from "@/app/store"
import { mutex } from "@/app/services/graphql/api/withTokenRefresh"
import { handleLogout, refresh } from "@/features/auth/authSlice"
import { enqueueSnackbar } from "@/features/notifier/notifierSlice"
import i18next from "i18next"

type RestAPIError = {
  data: {
    detail: string
  }
  status: number
}

function isExpiredToken(error: RestAPIError) {
  if (!("status" in error) || !("data" in error) || !("detail" in error.data)) {
    return false
  }

  if (error.status === 401 && error.data.detail === "Authentication failed") {
    return true
  }

  return false
}

const withBackendHandling =
  <BaseQuery extends BaseQueryFn>(baseQuery: BaseQuery) =>
  async <BaseQuery extends BaseQueryFn>(
    args: BaseQueryArg<BaseQuery>,
    api: BaseQueryApi,
    extraOptions: BaseQueryExtraOptions<BaseQuery>,
  ): Promise<
    QueryReturnValue<
      BaseQueryResult<BaseQuery>,
      BaseQueryError<BaseQuery>,
      BaseQueryMeta<BaseQuery>
    >
  > => {
    // Setup ready to modify args
    let adjustedArgs: FetchArgs

    // noinspection SuspiciousTypeOfGuard
    if (typeof args === "string") {
      adjustedArgs = { url: args }
    } else {
      adjustedArgs = { ...args }
    }

    // Clone FormData if needed
    if (adjustedArgs.body instanceof FormData) {
      const newFormData = new FormData()
      for (const [key, value] of adjustedArgs.body.entries()) {
        newFormData.append(key, value)
      }
      adjustedArgs.body = newFormData
    }

    if (adjustedArgs.params) {
      // Clone params if exist
      adjustedArgs.params = cloneDeep(adjustedArgs.params)
    } else {
      // Initialise params
      adjustedArgs.params = {}
    }

    // Initialise headers
    adjustedArgs.headers = new Headers(adjustedArgs.headers ?? ({} as any))

    let state = api.getState() as RootState

    if (!adjustedArgs.headers.has("Authorisation")) {
      const auth = state.auth.authenticationResult
      if (auth && auth.AccessToken && auth.IdToken) {
        adjustedArgs.headers.set("Authorisation", `Bearer ${auth.AccessToken}`)
        adjustedArgs.headers.set("X-IDENTITY", auth.IdToken)
      }
    }

    // Stringify any objects/arrays in params
    for (const [key, value] of Object.entries(adjustedArgs.params!)) {
      if (typeof value === "object" && value !== null) {
        adjustedArgs.params![key] = JSON.stringify(value)
      }
    }

    // Make request
    await mutex.waitForUnlock()

    type BaseQueryReturnValue = QueryReturnValue<
      BaseQueryResult<typeof baseQuery>,
      BaseQueryError<typeof baseQuery>,
      BaseQueryMeta<typeof baseQuery>
    >

    // Attempt the query
    let result = (await baseQuery(
      adjustedArgs,
      api,
      extraOptions,
    )) as BaseQueryReturnValue

    const error = result.error
    if (!error) {
      return result
    }

    if (!isExpiredToken(error as RestAPIError)) {
      return result
    }

    if (!state.auth.authenticationResult?.RefreshToken) {
      api.dispatch(handleLogout())

      return result
    }

    if (mutex.isLocked()) {
      await mutex.waitForUnlock()

      state = api.getState() as RootState
      if (state.auth.authenticationResult) {
        result = (await baseQuery(
          adjustedArgs,
          api,
          extraOptions,
        )) as BaseQueryReturnValue
      }
    } else {
      const release = await mutex.acquire()

      try {
        const refreshResult = await api.dispatch(
          refresh({ token: state.auth.authenticationResult.RefreshToken }),
        )

        if ("error" in refreshResult) {
          api.dispatch(handleLogout())

          api.dispatch(
            enqueueSnackbar({
              message:
                refreshResult.payload === "Invalid Refresh Token"
                  ? i18next.t("common:notifier.sessionExpired")
                  : i18next.t("common:notifier.errorWhileRefreshing"),
              options: {
                key: "login_refresh_error",
                variant: "error",
              },
            }),
          )

          return {
            error: refreshResult.payload,
          }
        }

        result = (await baseQuery(
          adjustedArgs,
          api,
          extraOptions,
        )) as BaseQueryReturnValue
      } finally {
        release()
      }
    }

    return result
  }

export default withBackendHandling

function getFromRequest(field: string, args: FetchArgs) {
  if (args.body) {
    if (args.body instanceof FormData && args.body.has(field)) {
      return args.body.get(field)
    } else if (typeof args.body === "string") {
      const params = new URLSearchParams(args.body)
      if (params.has(field)) {
        return params.get(field)
      }
    } else if (typeof args.body === "object" && args.body[field]) {
      return args.body[field]
    }
  }

  if (args.params && args.params[field]) {
    return args.params[field]
  }

  return undefined
}

function addToRequest(fieldsToAdd: Record<string, any>, args: FetchArgs) {
  const requestHasBody = !!args.method && args.method !== "GET"
  if (!requestHasBody) {
    // No body, so add to params

    args.params = merge(args.params, fieldsToAdd)

    return
  }

  // Body exists, so add to body where possible

  if (args.body instanceof FormData) {
    // Body is FormData

    for (const [key, value] of Object.entries(fieldsToAdd)) {
      if (typeof value === "object") {
        args.params![key] = value
      }

      args.body.append(key, value)
    }
  } else if (typeof args.body === "string") {
    // Body is URLSearchParams

    const params = new URLSearchParams(args.body)
    for (const [key, value] of Object.entries(fieldsToAdd)) {
      if (typeof value === "object") {
        args.params![key] = value
      }

      params.append(key, value)
    }

    args.body = params.toString()
  } else if (args.body) {
    // Body is JSON

    args.body = merge(cloneDeep(args.body), fieldsToAdd)
  } else {
    // Body is undefined

    args.body = {
      ...fieldsToAdd,
    }
  }
}
