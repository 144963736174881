import { RouteObject } from "react-router-dom"
import { lazy } from "react"
import ProtectedRoute from "@/components/ProtectedRoute"
import i18next from "i18next"
import { hasModuleRoutePermission } from "@/util/permissions"
import { PERMISSION_MODULES } from "@/constants/auth"

const CreateNewThread = lazy(
  () => import("@/pages/DiscussionForum/CreateThread/CreateThread"),
)

const route: RouteObject = {
  path: "/discussion-forum/new-thread",
  handle: {
    crumb: (data: string) =>
      data ?? i18next.t("label.newThread", { ns: "discussionForum" }),
  },
  children: [
    {
      index: true,
      element: (
        <ProtectedRoute
          hasPermission={hasModuleRoutePermission(
            PERMISSION_MODULES.discussionForum,
          )}
          redirectTo="/discussion-forum"
        >
          <CreateNewThread />
        </ProtectedRoute>
      ),
    },
  ],
}

export default route
